import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';

function Header() {
  return (
    <header className="header">
      <Link to="/">
        <img src="/images/Larry-Logo-v2.png" alt="Larry GPT Logo" className="logo" />
      </Link>
      {/* Add other header elements here if needed */}
    </header>
  );
}

export default Header; 