import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-links">
        <Link to="/polityka-prywatnosci">Polityka Prywatności</Link>
        {/* Add other links if needed */}
      </div>
      <p>© 2024 Larry GPT. Wszelkie prawa zastrzeżone.</p>
    </footer>
  );
}

export default Footer; 