import React from 'react';
import './App.css';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1 className="large-title">Polityka Prywatności</h1>
      <section>
        <h2 className="headline">1. Zbieranie Danych</h2>
        <p>Larry GPT nie zbiera żadnych danych osobowych, danych użytkowania ani innych danych identyfikacyjnych od swoich użytkowników. Żadne dane nie są zbierane. Aplikacja działa lokalnie na urządzeniu użytkownika i korzysta z API AI do generowania odpowiedzi. Wszystkie dane przetwarzane przez API są obsługiwane w czasie rzeczywistym i nie są przechowywane.</p>
      </section>
      <section>
        <h2 className="headline">2. Wykorzystanie Danych</h2>
        <p>Ponieważ Larry GPT nie zbiera żadnych danych, nie ma wykorzystania danych poza generowaniem odpowiedzi na podstawie danych wejściowych dostarczonych przez użytkownika podczas korzystania z aplikacji. Dane wejściowe są przetwarzane chwilowo i usuwane natychmiast po wygenerowaniu odpowiedzi. Żadne dane użytkownika nie są udostępniane stronom trzecim.</p>
      </section>
      <section>
        <h2 className="headline">3. Prawa Użytkownika</h2>
        <p>Ponieważ aplikacja nie przechowuje ani nie zbiera żadnych danych osobowych, nie ma danych, do których użytkownicy mogliby uzyskać dostęp, zaktualizować lub usunąć. Użytkownicy zachowują pełną kontrolę nad danymi wejściowymi, które dostarczają do aplikacji, ponieważ żadne dane nie są przechowywane.</p>
      </section>
      <section>
        <h2 className="headline">4. Bezpieczeństwo</h2>
        <p>Aplikacja nie przechowuje żadnych danych użytkownika, co z natury minimalizuje ryzyko. Integracja API przestrzega standardowych protokołów bezpieczeństwa branżowego, aby zapewnić bezpieczne przetwarzanie danych podczas interakcji w czasie rzeczywistym.</p>
      </section>
      <section>
        <h2 className="headline">5. Informacje Kontaktowe</h2>
        <p>Użytkownicy mogą skontaktować się z nami pod adresem arkadiastudio2024@gmail.com w przypadku jakichkolwiek pytań lub wątpliwości dotyczących prywatności.</p>
      </section>
    </div>
  );
}

export default PrivacyPolicy;